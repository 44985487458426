<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <div class="sign-up-card-container px-3 px-md-10 py-8 ">
          <div class="text-center mb-2">
            <h3 class="mb-0">
              <router-link to="/">
                <v-img
                  width="200"
                  src="@/assets/images/logo.png"
                  class="mx-auto"
                />
              </router-link>
            </h3>
          </div>
          <div class="text-center mb-10">
            <h3 class="mb-3">
              {{ $t("Rejoins le mouvement de la seconde main et vends sans frais !") }}
            </h3>
          </div>

          <signup-form />
        </div>
        <div class="py-4 grey lighten-2">
          <div class="text-center">
            <span class="grey--text text--darken-1">{{ $t("Vous avez déjà un compte ?") }} <router-link
              to="/login"
              class="ms-2 primary--text text--darken-1  font-600"
            >{{ $t("Connectez-vous") }}</router-link> </span>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import SocialLogin from '../components/SocialLogin.vue'
  import SignupForm from '../components/SignupForm.vue'

  export default {
    components: { SignupForm, SocialLogin },
    props: {
      hideExtra: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        valid: false,
        checkbox: false,
        loading: false,
        isPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],

      }
    },
    methods: {
      onSubmit () {
        const isFormValid = this.$refs.registerForm.validate()

        if (!isFormValid) return
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/register',
          data: { name: this.name, first_name: this.first_name, last_name: this.last_name, email: this.email, password: this.password },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          //localStorage.setItem('userData', JSON.stringify(response.data))
          //localStorage.setItem('login', true)
          this.setValue({ type: 'user', object: response.data })
          this.setValue({ type: 'login', object: true })
          window.location.href = '/'
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }

</style>
